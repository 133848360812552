/*
 * Fonts
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 */

import FontFaceObserver from 'fontfaceobserver';
import addClass from '@reef/js/fn/attributes/addClass';
import select from '@reef/js/fn/select/select';

// Fonts
const observers = [];
observers.push(new FontFaceObserver('gt-super-display').load(null, 5000));
observers.push(new FontFaceObserver('matter').load(null, 5000));

Promise.all(observers)
    .then(() => {
        addClass(select('html'), 'fonts-loaded');
    })
    .catch(() => {
        addClass(select('html'), 'fonts-loaded');
    });
